
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import Calendar from 'primevue/calendar';
import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import * as ApiInterfaces from '@/models/Interfaces';
import Q from 'q';
import { Currency, ProviderCurrencyRate } from '@/models/Interfaces';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import { generalStore } from '@/store';

import { defineComponent, ref, computed, onMounted } from 'vue';

export default defineComponent({
    name: 'ProviderCurrencyRate',
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    components: {
        Multiselect,
        Calendar
    },
    async setup(props) {
        const { t } = useI18n();
        const currencies = computed<Currency[]>(() => generalStore.getters.currenciesWithoutLocal);
        const rate = ref<ProviderCurrencyRate>({ id: '', currencyId: '', rate: null, startDate: '', createDate: '' });
        const startDate = ref<Date | null>(null);
        function dateChanged() {
            rate.value.startDate = new Date(
                moment(startDate.value)
                    .utcOffset(0, true)
                    .format()
            ).toUTCString();
        }

        function cancel() {
            router.back();
        }

        async function saveRate() {
            swal.showLoading();
            const request: ApiInterfaces.SaveProviderCurrencyRateRequest = {
                item: {
                    id: rate.value.id,
                    currencyId: rate.value.currencyId,
                    rate: rate.value.rate,
                    startDate: rate.value.startDate,
                    createDate: null
                }
            };
            const apiResult = await api.saveProviderCurrencyRate(request);
            if (apiResult.errorMessage) {
                swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
                return;
            }
            if (apiResult.data) {
                if (apiResult.data.validationError) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.data.validationError });
                } else {
                    generalStore.commit('updateCurrencyRates', apiResult.data.currencyRates);
                    const title = rate.value.id ? t('msg.currency-updates') : t('msg.currency-created');
                    await swal.fire({
                        title: title,
                        position: 'center',
                        icon: 'success',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    cancel();
                }
            }
        }
        const onMountedCall = async () => {
            swal.showLoading();
            if (props.id && props.id != '') {
                const apiResult = await api.getProviderCurrencyRate(props.id);
                if (apiResult.errorMessage) {
                    swal.fire({ icon: 'error', title: 'Oops...', text: apiResult.errorMessage });
                }
                if (apiResult.data) {
                    rate.value = apiResult.data.item;
                    startDate.value = new Date(apiResult.data.item.startDate);
                }
            }
            swal.close();
        };
        onMounted(onMountedCall);
        return { dateChanged, saveRate, cancel, currencies, rate, startDate };
    }
});
